import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import axios from "axios";

const App = () => {
  const [searchParams] = useSearchParams();

  const verify = async (serial) => {
    const res = await axios.get(`https://us-central1-kiosklogstest.cloudfunctions.net/serialSentry?serial=${serial}`);
    console.log(res)
    Sentry.captureMessage(`Validate Serial ${JSON.stringify({ 
      timestamp: new Date(),
      service: 'Restaurant Default',
      env: 'uat',
      version: '0.0.1',
      organisation: 'TYIMXJyrsby3hAf4Z1Wk',
      storeId: 'fm-generic',
      serial
    })}`, "debug");
  }

  useEffect(() => {
    const serial = searchParams.get('serial');
    if (serial) {
      Sentry.setTag('serial', serial);
      verify(serial)
    }
  }, [searchParams])

  return (
    <>
      <h1>{`Serial: ${searchParams.get('serial')}`}</h1>
      <div>
        <ul>
          <li><Link to={`home`}>Home Page</Link></li>
          <li><Link to={`test`}>Test Serial</Link></li>
        </ul>
      </div>
    </>
  );
}

export default App;
