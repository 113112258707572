import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,  RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import App from './App';
import Home from './Home';
import Test from './Test';
import ErrorPage from './Error';

Sentry.init({
  dsn: 'https://cadc122e763841e5b5bdcd4ba697cbcf@o4504492113395712.ingest.sentry.io/4504492169232384',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: 'Restaurant Default@0.0.1',
  environment: 'uat',
  initialScope: {
    tags: { "serial": 'undefined', "organisation": 'TYIMXJyrsby3hAf4Z1Wk', "storeId": 'fm-generic' },
  },
  autoSessionTracking: false
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/test",
    element: <Test />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
