import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
      Sentry.captureMessage(error.statusText || error.message, "error");
    }
  }, [error]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}