import { useEffect } from "react";
import { format } from 'date-fns';
import * as Sentry from "@sentry/react";

const Test = () => {

  useEffect(() => {
    Sentry.captureMessage(`Page Visited ${JSON.stringify({ 
      name: 'Test',
      timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      service: 'Restaurant Default',
      env: 'uat',
      version: '0.0.1',
      organisation: 'TYIMXJyrsby3hAf4Z1Wk',
      storeId: 'fm-generic',
    })}`, "info");
  }, [])

  return (
    <div>Test</div>
  );
}

export default Test;
